import React from 'react';

function NavItem({
	children,
	fill = true,
	handleClick,
	selected,
	size = '5',
	stroke = 0,
	title
}) {
	return (
		<li
			className={`flex w-auto scale-100 transform cursor-pointer items-center justify-start rounded-md px-3 py-2 text-amBlue transition duration-200 ease-in-out md:mb-2 md:w-full md:px-6 md:py-3 ${
				selected
					? 'darkBlue-shadow z-10 bg-amDarkBlue dark:bg-white md:scale-105'
					: 'hover:bg-gray-200 dark:hover:bg-amDarkBlue'
			}`}
			onClick={handleClick}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				fill={fill ? 'currentColor' : 'none'}
				stroke={fill ? 'currentColor' : 'none'}
				viewBox="0 0 24 24"
				className={`${
					fill ? 'fill-current' : 'stroke-current'
				} h-${size} w-${size}`}
				strokeWidth={stroke}
			>
				{children}
			</svg>
			<p
				className={`ml-2 whitespace-nowrap text-sm font-medium md:whitespace-normal ${
					selected
						? 'block text-white text-black'
						: 'hidden text-black md:block'
				}`}
			>
				{title}
			</p>
		</li>
	);
}

export default NavItem;
