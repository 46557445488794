import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Modal from "react-awesome-modal";
import ReactMarkdown from "react-markdown";
import Banner from "./assets/banner-procesos-administrativos.jpeg";
import Placeholder from "./assets/placeholder.svg";
import { ReactComponent as SearchIcon } from "./assets/search.svg";
import { ReactComponent as Atencion } from "./assets/atencion.svg";
import { ReactComponent as Correo } from "./assets/correo.svg";
import close from "../../assets/logo/close.svg";
import Loader from "../../components/Loader/Loader";
import Index from "../../components/Footer";
import { isLocal, isFromDev, isFromQA } from "./../../core/Ambientes";
import i18next from "i18next";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";

const fakeStockER = [
	{
		empleado: '301723',
		email: 'jalvaradoj@aeromexico.com',
		nombre: 'Jose Luis Alvarado Jimenez',
		bloque: '1',
		ltip_rsu_1_23: '100',
		ltip_rsu_1_24: '200',
		ltip_rsu_2_24: '300',
		mip_rsu_1_23: '101',
		mip_rsu_2_24: '202',
		mip_psu_1_24: '303',
		total_tal_01: '1206',
		ltip_rsu_3_25: '400',
		ltip_rsu_2_25: '500',
		ltip_rsu_3_26: '600',
		mip_rsu_3_25: '700',
		mip_psu_2_25: '900',
		total_anl_02: '3000',
		oca_1_24: '100',
		oca_2_24: "200"
	},
	{
		empleado: '301723',
		email: 'jtapias@aeromexico.com',
		nombre: 'Jessica Tapia Silva',
		bloque: '2',
		ltip_rsu_1_23: '10000',
		ltip_rsu_1_24: '2000',
		ltip_rsu_2_24: '3000',
		mip_rsu_1_23: '1010',
		mip_rsu_2_24: '2020',
		mip_psu_1_24: '3030',
		total_tal_01: '12060',
		ltip_rsu_3_25: '4000',
		ltip_rsu_2_25: '5000',
		ltip_rsu_3_26: '6000',
		mip_rsu_3_25: '7000',
		mip_psu_2_25: '8000',
		total_anl_02: '30000',
		oca_1_24: '100',
		oca_2_24: "200"
	},
	{
		empleado: '301723',
		email: 'josmartinezp@aeromexico.com',
		nombre: 'Jose Arturo Martinez Perez',
		bloque: '3',
		ltip_rsu_1_23: '10',
		ltip_rsu_1_24: '200',
		ltip_rsu_2_24: '3',
		mip_rsu_1_23: '11',
		mip_rsu_2_24: '22',
		mip_psu_1_24: '33',
		total_tal_01: '1260',
		ltip_rsu_3_25: '300',
		ltip_rsu_2_25: '100',
		ltip_rsu_3_26: '200',
		mip_rsu_3_25: '700',
		mip_psu_2_25: '600',
		total_anl_02: '2800',
		oca_1_24: '100',
		oca_2_24: "200"
	},
	{
		empleado: '301723',
		email: 'arivasm@aeromexico.com',
		nombre: 'Ana Luisa Rivas Manriquez',
		bloque: '4',
		ltip_rsu_1_23: '10',
		ltip_rsu_1_24: '200',
		ltip_rsu_2_24: '3',
		mip_rsu_1_23: '11',
		mip_rsu_2_24: '22',
		mip_psu_1_24: '33',
		total_tal_01: '1260',
		ltip_rsu_3_25: '300',
		ltip_rsu_2_25: '100',
		ltip_rsu_3_26: '200',
		mip_rsu_3_25: '700',
		mip_psu_2_25: '600',
		total_anl_02: '2800',
		oca_1_24: '100',
		oca_2_24: "200"
	},
	{
		empleado: '301723',
		email: 'hmarind@aeromexico.com',
		nombre: 'Hector Marin Diego',
		bloque: '5',
		ltip_rsu_1_23: '10',
		ltip_rsu_1_24: '200',
		ltip_rsu_2_24: '3',
		mip_rsu_1_23: '11',
		mip_rsu_2_24: '22',
		mip_psu_1_24: '33',
		total_tal_01: '1260',
		ltip_rsu_3_25: '300',
		ltip_rsu_2_25: '100',
		ltip_rsu_3_26: '200',
		mip_rsu_3_25: '700',
		mip_psu_2_25: '600',
		total_anl_02: '2800',
		oca_1_24: '100',
		oca_2_24: "200"
	},
	{
		empleado: '301723',
		email: 'jperezi@aeromexico.com',
		nombre: 'Jose Fernando Perez Izquierdo',
		bloque: '6',
		ltip_rsu_1_23: '10',
		ltip_rsu_1_24: '200',
		ltip_rsu_2_24: '3',
		mip_rsu_1_23: '11',
		mip_rsu_2_24: '22',
		mip_psu_1_24: '33',
		total_tal_01: '1260',
		ltip_rsu_3_25: '300',
		ltip_rsu_2_25: '100',
		ltip_rsu_3_26: '200',
		mip_rsu_3_25: '700',
		mip_psu_2_25: '600',
		total_anl_02: '2800',
		oca_1_24: '100',
		oca_2_24: "200"
	},
	{	empleado:'ABU JAMRA SALGADO ROCHA FERNANDO GUILHERME',	email:'fernando.rocha@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'826',	ltip_rsu_1_24:'790',	ltip_rsu_2_24:'827',	mip_rsu_1_23:'2130',	mip_rsu_2_24:'2130',	mip_psu_1_24:'9585',	oca_1_24:'0',	total_tal_01:'16288',	ltip_rsu_3_25:'827',	ltip_rsu_2_25:'790',	ltip_rsu_3_26:'790',	mip_rsu_3_25:'2130',	mip_psu_2_25:'9585',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'14122',	},
{	empleado:'MULINELLI AVILES GIANCARLO',	email:'gmulinelli@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'666',	ltip_rsu_1_24:'694',	ltip_rsu_2_24:'667',	mip_rsu_1_23:'3550',	mip_rsu_2_24:'3550',	mip_psu_1_24:'15970',	oca_1_24:'0',	total_tal_01:'25097',	ltip_rsu_3_25:'667',	ltip_rsu_2_25:'693',	ltip_rsu_3_26:'693',	mip_rsu_3_25:'3550',	mip_psu_2_25:'15970',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'21573',	},
{	empleado:'PEREZ ZUBIRIA ALEJANDRO',	email:'aperezz@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'876',	ltip_rsu_1_24:'930',	ltip_rsu_2_24:'877',	mip_rsu_1_23:'3550',	mip_rsu_2_24:'3550',	mip_psu_1_24:'15970',	oca_1_24:'0',	total_tal_01:'25753',	ltip_rsu_3_25:'877',	ltip_rsu_2_25:'930',	ltip_rsu_3_26:'930',	mip_rsu_3_25:'3550',	mip_psu_2_25:'15970',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'22257',	},
{	empleado:'JACOME ARMIDA JORGE LUIS',	email:'jjacome@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'896',	ltip_rsu_1_24:'930',	ltip_rsu_2_24:'897',	mip_rsu_1_23:'3550',	mip_rsu_2_24:'3550',	mip_psu_1_24:'15970',	oca_1_24:'0',	total_tal_01:'25793',	ltip_rsu_3_25:'897',	ltip_rsu_2_25:'930',	ltip_rsu_3_26:'930',	mip_rsu_3_25:'3550',	mip_psu_2_25:'15970',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'22277',	},
{	empleado:'MENDEZ GONZALEZ ALEJANDRO NICOLAS',	email:'amendez@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'906',	ltip_rsu_1_24:'950',	ltip_rsu_2_24:'907',	mip_rsu_1_23:'4970',	mip_rsu_2_24:'4970',	mip_psu_1_24:'22360',	oca_1_24:'0',	total_tal_01:'35063',	ltip_rsu_3_25:'907',	ltip_rsu_2_25:'950',	ltip_rsu_3_26:'950',	mip_rsu_3_25:'4970',	mip_psu_2_25:'22360',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'30137',	},
{	empleado:'ALVAREZ MATAR MAX EDUARDO',	email:'malvarez@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'766',	ltip_rsu_1_24:'796',	ltip_rsu_2_24:'767',	mip_rsu_1_23:'3550',	mip_rsu_2_24:'3550',	mip_psu_1_24:'15970',	oca_1_24:'0',	total_tal_01:'25399',	ltip_rsu_3_25:'767',	ltip_rsu_2_25:'797',	ltip_rsu_3_26:'797',	mip_rsu_3_25:'3550',	mip_psu_2_25:'15970',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'21881',	},
{	empleado:'LAGUNA CASTELLANOS CESAR AUGUSTO',	email:'cclaguna@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'546',	ltip_rsu_1_24:'566',	ltip_rsu_2_24:'547',	mip_rsu_1_23:'3550',	mip_rsu_2_24:'3550',	mip_psu_1_24:'15970',	oca_1_24:'0',	total_tal_01:'24729',	ltip_rsu_3_25:'547',	ltip_rsu_2_25:'567',	ltip_rsu_3_26:'567',	mip_rsu_3_25:'3550',	mip_psu_2_25:'15970',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'21201',	},
{	empleado:'FERNANDEZ OJEDA ANTONIO',	email:'afernandezo@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'406',	ltip_rsu_1_24:'426',	ltip_rsu_2_24:'407',	mip_rsu_1_23:'2130',	mip_rsu_2_24:'2130',	mip_psu_1_24:'9585',	oca_1_24:'0',	total_tal_01:'15084',	ltip_rsu_3_25:'407',	ltip_rsu_2_25:'427',	ltip_rsu_3_26:'427',	mip_rsu_3_25:'2130',	mip_psu_2_25:'9585',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'12976',	},
{	empleado:'MARTIN MATOS XIOMARA',	email:'xmartin@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'466',	ltip_rsu_1_24:'490',	ltip_rsu_2_24:'467',	mip_rsu_1_23:'1210',	mip_rsu_2_24:'1210',	mip_psu_1_24:'5430',	oca_1_24:'0',	total_tal_01:'9273',	ltip_rsu_3_25:'467',	ltip_rsu_2_25:'490',	ltip_rsu_3_26:'490',	mip_rsu_3_25:'1210',	mip_psu_2_25:'5430',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8087',	},
{	empleado:'ZAPATA CANTU JOSE ANGEL',	email:'jazapatac@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'546',	ltip_rsu_1_24:'570',	ltip_rsu_2_24:'547',	mip_rsu_1_23:'2130',	mip_rsu_2_24:'2130',	mip_psu_1_24:'9585',	oca_1_24:'0',	total_tal_01:'15508',	ltip_rsu_3_25:'547',	ltip_rsu_2_25:'570',	ltip_rsu_3_26:'570',	mip_rsu_3_25:'2130',	mip_psu_2_25:'9585',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'13402',	},
{	empleado:'CASTRO LOPEZ JAIME HECTOR',	email:'jcastrolo@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'546',	ltip_rsu_1_24:'566',	ltip_rsu_2_24:'547',	mip_rsu_1_23:'2130',	mip_rsu_2_24:'2130',	mip_psu_1_24:'9585',	oca_1_24:'0',	total_tal_01:'15504',	ltip_rsu_3_25:'547',	ltip_rsu_2_25:'567',	ltip_rsu_3_26:'567',	mip_rsu_3_25:'2130',	mip_psu_2_25:'9585',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'13396',	},
{	empleado:'CERVANTES MUNOZ CLAUDIA ANGELICA',	email:'ccervantes@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'524',	ltip_rsu_1_24:'664',	ltip_rsu_2_24:'523',	mip_rsu_1_23:'3550',	mip_rsu_2_24:'3550',	mip_psu_1_24:'15970',	oca_1_24:'0',	total_tal_01:'24781',	ltip_rsu_3_25:'523',	ltip_rsu_2_25:'663',	ltip_rsu_3_26:'663',	mip_rsu_3_25:'3550',	mip_psu_2_25:'15970',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'21369',	},
{	empleado:'GUTIERREZ SALAZAR RICARDO ISMAEL',	email:'rigutierrez@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'396',	ltip_rsu_1_24:'470',	ltip_rsu_2_24:'397',	mip_rsu_1_23:'2840',	mip_rsu_2_24:'2840',	mip_psu_1_24:'12775',	oca_1_24:'0',	total_tal_01:'19718',	ltip_rsu_3_25:'397',	ltip_rsu_2_25:'470',	ltip_rsu_3_26:'470',	mip_rsu_3_25:'2840',	mip_psu_2_25:'12775',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'16952',	},
{	empleado:'MENCHACA GARZA CRISTINA',	email:'cmenchaca@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'480',	ltip_rsu_1_24:'514',	ltip_rsu_2_24:'480',	mip_rsu_1_23:'2840',	mip_rsu_2_24:'2840',	mip_psu_1_24:'12775',	oca_1_24:'0',	total_tal_01:'19929',	ltip_rsu_3_25:'480',	ltip_rsu_2_25:'513',	ltip_rsu_3_26:'513',	mip_rsu_3_25:'2840',	mip_psu_2_25:'12775',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'17121',	},
{	empleado:'CANO RAMIREZ JORGE',	email:'jcanor@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'434',	ltip_rsu_1_24:'450',	ltip_rsu_2_24:'433',	mip_rsu_1_23:'1780',	mip_rsu_2_24:'1780',	mip_psu_1_24:'7990',	oca_1_24:'0',	total_tal_01:'12867',	ltip_rsu_3_25:'433',	ltip_rsu_2_25:'450',	ltip_rsu_3_26:'450',	mip_rsu_3_25:'1780',	mip_psu_2_25:'7990',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'11103',	},
{	empleado:'PINA BILBAO MIKEL',	email:'mpina@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'520',	ltip_rsu_1_24:'554',	ltip_rsu_2_24:'520',	mip_rsu_1_23:'3550',	mip_rsu_2_24:'3550',	mip_psu_1_24:'15970',	oca_1_24:'0',	total_tal_01:'24664',	ltip_rsu_3_25:'520',	ltip_rsu_2_25:'553',	ltip_rsu_3_26:'553',	mip_rsu_3_25:'3550',	mip_psu_2_25:'15970',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'21146',	},
{	empleado:'GOMEZ RODRIGUEZ LAURA LILIANA',	email:'llgomez@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'500',	ltip_rsu_1_24:'520',	ltip_rsu_2_24:'500',	mip_rsu_1_23:'2840',	mip_rsu_2_24:'2840',	mip_psu_1_24:'12775',	oca_1_24:'0',	total_tal_01:'19975',	ltip_rsu_3_25:'500',	ltip_rsu_2_25:'520',	ltip_rsu_3_26:'520',	mip_rsu_3_25:'2840',	mip_psu_2_25:'12775',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'17155',	},
{	empleado:'SUAREZ CHAVEZ SERGIO ANGEL RAFAEL',	email:'rsuarezc@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'474',	ltip_rsu_1_24:'496',	ltip_rsu_2_24:'473',	mip_rsu_1_23:'1780',	mip_rsu_2_24:'1780',	mip_psu_1_24:'7990',	oca_1_24:'0',	total_tal_01:'12993',	ltip_rsu_3_25:'473',	ltip_rsu_2_25:'497',	ltip_rsu_3_26:'497',	mip_rsu_3_25:'1780',	mip_psu_2_25:'7990',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'11237',	},
{	empleado:'OLMOS DEL AMO PABLO',	email:'polmos@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'316',	ltip_rsu_1_24:'330',	ltip_rsu_2_24:'317',	mip_rsu_1_23:'1140',	mip_rsu_2_24:'1140',	mip_psu_1_24:'5115',	oca_1_24:'0',	total_tal_01:'8358',	ltip_rsu_3_25:'317',	ltip_rsu_2_25:'330',	ltip_rsu_3_26:'330',	mip_rsu_3_25:'1140',	mip_psu_2_25:'5115',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'7232',	},
{	empleado:'SANCHEZ BARRIENTOS DAVID',	email:'dsanchez@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'326',	ltip_rsu_1_24:'344',	ltip_rsu_2_24:'327',	mip_rsu_1_23:'1140',	mip_rsu_2_24:'1140',	mip_psu_1_24:'5115',	oca_1_24:'0',	total_tal_01:'8392',	ltip_rsu_3_25:'327',	ltip_rsu_2_25:'343',	ltip_rsu_3_26:'343',	mip_rsu_3_25:'1140',	mip_psu_2_25:'5115',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'7268',	},
{	empleado:'ROCHO PORRAS ISAAC',	email:'irocho@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'334',	ltip_rsu_1_24:'354',	ltip_rsu_2_24:'333',	mip_rsu_1_23:'1780',	mip_rsu_2_24:'1780',	mip_psu_1_24:'7990',	oca_1_24:'0',	total_tal_01:'12571',	ltip_rsu_3_25:'333',	ltip_rsu_2_25:'353',	ltip_rsu_3_26:'353',	mip_rsu_3_25:'1780',	mip_psu_2_25:'7990',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'10809',	},
{	empleado:'SANDOVAL PIEMONTE MANUEL RODRIGO',	email:'rsandoval@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'300',	ltip_rsu_1_24:'380',	ltip_rsu_2_24:'300',	mip_rsu_1_23:'1780',	mip_rsu_2_24:'1780',	mip_psu_1_24:'7990',	oca_1_24:'0',	total_tal_01:'12530',	ltip_rsu_3_25:'300',	ltip_rsu_2_25:'380',	ltip_rsu_3_26:'380',	mip_rsu_3_25:'1780',	mip_psu_2_25:'7990',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'10830',	},
{	empleado:'CHAVEZ CRUZ VICTOR HUGO',	email:'vhchavezc@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'294',	ltip_rsu_1_24:'304',	ltip_rsu_2_24:'293',	mip_rsu_1_23:'1350',	mip_rsu_2_24:'1350',	mip_psu_1_24:'6075',	oca_1_24:'0',	total_tal_01:'9666',	ltip_rsu_3_25:'293',	ltip_rsu_2_25:'303',	ltip_rsu_3_26:'303',	mip_rsu_3_25:'1350',	mip_psu_2_25:'6075',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8324',	},
{	empleado:'BAEZ MATA MARIO ALBERTO',	email:'mbaez@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'340',	ltip_rsu_1_24:'374',	ltip_rsu_2_24:'340',	mip_rsu_1_23:'2130',	mip_rsu_2_24:'2130',	mip_psu_1_24:'9585',	oca_1_24:'0',	total_tal_01:'14899',	ltip_rsu_3_25:'340',	ltip_rsu_2_25:'373',	ltip_rsu_3_26:'373',	mip_rsu_3_25:'2130',	mip_psu_2_25:'9585',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'12801',	},
{	empleado:'ARCOVERDE GOMES EDGAR',	email:'egomes@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'326',	ltip_rsu_1_24:'340',	ltip_rsu_2_24:'327',	mip_rsu_1_23:'1780',	mip_rsu_2_24:'1780',	mip_psu_1_24:'7990',	oca_1_24:'0',	total_tal_01:'12543',	ltip_rsu_3_25:'327',	ltip_rsu_2_25:'340',	ltip_rsu_3_26:'340',	mip_rsu_3_25:'1780',	mip_psu_2_25:'7990',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'10777',	},
{	empleado:'PASTRANA MACIA CHRISTIAN',	email:'chpastrana@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'376',	ltip_rsu_1_24:'396',	ltip_rsu_2_24:'377',	mip_rsu_1_23:'1570',	mip_rsu_2_24:'1570',	mip_psu_1_24:'7030',	oca_1_24:'0',	total_tal_01:'11319',	ltip_rsu_3_25:'377',	ltip_rsu_2_25:'397',	ltip_rsu_3_26:'397',	mip_rsu_3_25:'1570',	mip_psu_2_25:'7030',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'9771',	},
{	empleado:'MARTINEZ MARTINEZ DANIEL',	email:'dmartinezm@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'300',	ltip_rsu_1_24:'304',	ltip_rsu_2_24:'300',	mip_rsu_1_23:'1350',	mip_rsu_2_24:'1350',	mip_psu_1_24:'6075',	oca_1_24:'0',	total_tal_01:'9679',	ltip_rsu_3_25:'300',	ltip_rsu_2_25:'303',	ltip_rsu_3_26:'303',	mip_rsu_3_25:'1350',	mip_psu_2_25:'6075',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8331',	},
{	empleado:'ROMAN CHAVEZ ENRIQUE',	email:'enroman@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'300',	ltip_rsu_1_24:'316',	ltip_rsu_2_24:'300',	mip_rsu_1_23:'1070',	mip_rsu_2_24:'1070',	mip_psu_1_24:'4795',	oca_1_24:'0',	total_tal_01:'7851',	ltip_rsu_3_25:'300',	ltip_rsu_2_25:'317',	ltip_rsu_3_26:'317',	mip_rsu_3_25:'1070',	mip_psu_2_25:'4795',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'6799',	},
{	empleado:'PARADA DOMMARCO ELVIRA',	email:'eparada@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'284',	ltip_rsu_1_24:'296',	ltip_rsu_2_24:'283',	mip_rsu_1_23:'1420',	mip_rsu_2_24:'1420',	mip_psu_1_24:'6390',	oca_1_24:'0',	total_tal_01:'10093',	ltip_rsu_3_25:'283',	ltip_rsu_2_25:'297',	ltip_rsu_3_26:'297',	mip_rsu_3_25:'1420',	mip_psu_2_25:'6390',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8687',	},
{	empleado:'SOLER MORENO FEDERICO',	email:'fsoler@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'296',	ltip_rsu_1_24:'314',	ltip_rsu_2_24:'297',	mip_rsu_1_23:'1350',	mip_rsu_2_24:'1350',	mip_psu_1_24:'6075',	oca_1_24:'0',	total_tal_01:'9682',	ltip_rsu_3_25:'297',	ltip_rsu_2_25:'313',	ltip_rsu_3_26:'313',	mip_rsu_3_25:'1350',	mip_psu_2_25:'6075',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8348',	},
{	empleado:'GARCIA TAPIA ERNESTO',	email:'ergarcia@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'326',	ltip_rsu_1_24:'336',	ltip_rsu_2_24:'327',	mip_rsu_1_23:'1070',	mip_rsu_2_24:'1070',	mip_psu_1_24:'4795',	oca_1_24:'0',	total_tal_01:'7924',	ltip_rsu_3_25:'327',	ltip_rsu_2_25:'337',	ltip_rsu_3_26:'337',	mip_rsu_3_25:'1070',	mip_psu_2_25:'4795',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'6866',	},
{	empleado:'CASARIN TANAHARA LUIS ANGEL',	email:'lcasarin@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'290',	ltip_rsu_1_24:'300',	ltip_rsu_2_24:'290',	mip_rsu_1_23:'1350',	mip_rsu_2_24:'1350',	mip_psu_1_24:'6075',	oca_1_24:'0',	total_tal_01:'9655',	ltip_rsu_3_25:'290',	ltip_rsu_2_25:'300',	ltip_rsu_3_26:'300',	mip_rsu_3_25:'1350',	mip_psu_2_25:'6075',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8315',	},
{	empleado:'NAKAMURA SAVOY DAVID MASAO',	email:'dnakamura@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'426',	ltip_rsu_1_24:'446',	ltip_rsu_2_24:'427',	mip_rsu_1_23:'1350',	mip_rsu_2_24:'1350',	mip_psu_1_24:'6075',	oca_1_24:'0',	total_tal_01:'10074',	ltip_rsu_3_25:'427',	ltip_rsu_2_25:'447',	ltip_rsu_3_26:'447',	mip_rsu_3_25:'1350',	mip_psu_2_25:'6075',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8746',	},
{	empleado:'ALPIZAR MOREIRA CARLOS ENRIQUE',	email:'calpizar@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'434',	ltip_rsu_1_24:'546',	ltip_rsu_2_24:'433',	mip_rsu_1_23:'1350',	mip_rsu_2_24:'1350',	mip_psu_1_24:'6075',	oca_1_24:'0',	total_tal_01:'10188',	ltip_rsu_3_25:'433',	ltip_rsu_2_25:'547',	ltip_rsu_3_26:'547',	mip_rsu_3_25:'1350',	mip_psu_2_25:'6075',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8952',	},
{	empleado:'GARCIA URIBE CESAR',	email:'cgarcia@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'526',	ltip_rsu_1_24:'474',	ltip_rsu_2_24:'527',	mip_rsu_1_23:'1350',	mip_rsu_2_24:'1350',	mip_psu_1_24:'6075',	oca_1_24:'0',	total_tal_01:'10302',	ltip_rsu_3_25:'527',	ltip_rsu_2_25:'473',	ltip_rsu_3_26:'473',	mip_rsu_3_25:'1350',	mip_psu_2_25:'6075',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8898',	},
{	empleado:'MEDELLIN ENRIQUEZ DANIEL',	email:'dmedellin@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'374',	ltip_rsu_1_24:'396',	ltip_rsu_2_24:'373',	mip_rsu_1_23:'1780',	mip_rsu_2_24:'1780',	mip_psu_1_24:'7990',	oca_1_24:'0',	total_tal_01:'12693',	ltip_rsu_3_25:'373',	ltip_rsu_2_25:'397',	ltip_rsu_3_26:'397',	mip_rsu_3_25:'1780',	mip_psu_2_25:'7990',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'10937',	},
{	empleado:'FABIAN VILLALOBOS VICTOR MANUEL',	email:'vfabian@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'310',	ltip_rsu_1_24:'324',	ltip_rsu_2_24:'310',	mip_rsu_1_23:'1350',	mip_rsu_2_24:'1350',	mip_psu_1_24:'6075',	oca_1_24:'0',	total_tal_01:'9719',	ltip_rsu_3_25:'310',	ltip_rsu_2_25:'323',	ltip_rsu_3_26:'323',	mip_rsu_3_25:'1350',	mip_psu_2_25:'6075',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8381',	},
{	empleado:'HERNANDEZ MORENO JORGE EDUARDO',	email:'jhernandez@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'346',	ltip_rsu_1_24:'360',	ltip_rsu_2_24:'347',	mip_rsu_1_23:'1350',	mip_rsu_2_24:'1350',	mip_psu_1_24:'6075',	oca_1_24:'0',	total_tal_01:'9828',	ltip_rsu_3_25:'347',	ltip_rsu_2_25:'360',	ltip_rsu_3_26:'360',	mip_rsu_3_25:'1350',	mip_psu_2_25:'6075',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8492',	},
{	empleado:'HERNANDEZ PINA SAUL',	email:'spina@aeromexico.com',	bloque:'1',	ltip_rsu_1_23:'270',	ltip_rsu_1_24:'280',	ltip_rsu_2_24:'270',	mip_rsu_1_23:'1350',	mip_rsu_2_24:'1350',	mip_psu_1_24:'6075',	oca_1_24:'0',	total_tal_01:'9595',	ltip_rsu_3_25:'270',	ltip_rsu_2_25:'280',	ltip_rsu_3_26:'280',	mip_rsu_3_25:'1350',	mip_psu_2_25:'6075',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'8255',	},
{	empleado:'ROSALES ERIVES GUILLERMO',	email:'grosales@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'320',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'790',	mip_rsu_2_24:'790',	mip_psu_1_24:'3520',	oca_1_24:'0',	total_tal_01:'5420',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'320',	ltip_rsu_3_26:'320',	mip_rsu_3_25:'790',	mip_psu_2_25:'3520',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'4950',	},
{	empleado:'GUTIERREZ MARTINEZ JOSE ALBERTO CUITLAHUAC',	email:'jgutierrezma@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'840',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'890',	mip_rsu_2_24:'890',	mip_psu_1_24:'4000',	oca_1_24:'0',	total_tal_01:'6620',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'840',	ltip_rsu_3_26:'840',	mip_rsu_3_25:'890',	mip_psu_2_25:'4000',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'6570',	},
{	empleado:'TREVINO ALVA BERENICE SOLEDAD',	email:'btrevino@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'74',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3314',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'73',	ltip_rsu_3_26:'73',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2886',	},
{	empleado:'ROMERO CORONEL JOSE CHRISTIAN',	email:'jcromero@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'84',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3324',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'83',	ltip_rsu_3_26:'83',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2906',	},
{	empleado:'MONTEVERDE GOMEZ ROBERTO',	email:'rmonteverde@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'84',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3324',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'83',	ltip_rsu_3_26:'83',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2906',	},
{	empleado:'TAPIA CORONA ELIAS JORGE ALBERTO',	email:'ejtapia@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'300',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'790',	mip_rsu_2_24:'790',	mip_psu_1_24:'3520',	oca_1_24:'0',	total_tal_01:'5400',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'300',	ltip_rsu_3_26:'300',	mip_rsu_3_25:'790',	mip_psu_2_25:'3520',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'4910',	},
{	empleado:'GUZMAN BERAZALUCE CARLOS',	email:'cguzmanb@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'84',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3324',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'83',	ltip_rsu_3_26:'83',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2906',	},
{	empleado:'RESENDIZ GAMBOA MARIA FERNANDA',	email:'mfresendiz@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'74',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3314',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'73',	ltip_rsu_3_26:'73',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2886',	},
{	empleado:'BLAZQUEZ REYES ROCIO',	email:'rblazquez@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'176',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3416',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'177',	ltip_rsu_3_26:'177',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'3094',	},
{	empleado:'SPERANZA PASQUALE',	email:'psperanza@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'114',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3354',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'113',	ltip_rsu_3_26:'113',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2966',	},
{	empleado:'MEJIA HUERTA FELIX BENJAMIN',	email:'fmejiah@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'66',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3306',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'67',	ltip_rsu_3_26:'67',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2874',	},
{	empleado:'STANKIEWICZ CHAVEZ ANGELINA GRISELDA ',	email:'astankiewicz@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'66',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3306',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'67',	ltip_rsu_3_26:'67',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2874',	},
{	empleado:'RUIZ DAVIES FEDERICO JOHN',	email:'fjruiz@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'60',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1920',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'60',	ltip_rsu_3_26:'60',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1690',	},
{	empleado:'SEVY REZNIK RAFAEL',	email:'rsevy@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'84',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3324',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'83',	ltip_rsu_3_26:'83',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2906',	},
{	empleado:'ESCAMILLA EMBARCADERO JUAN CARLOS',	email:'jescamilla@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'76',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3316',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'77',	ltip_rsu_3_26:'77',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2894',	},
{	empleado:'VILLASENOR CORIA RICARDO',	email:'rvillasenor@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'74',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3314',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'73',	ltip_rsu_3_26:'73',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2886',	},
{	empleado:'MADRAZO UBACH MARIO',	email:'mmadrazo@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'70',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'720',	mip_rsu_2_24:'720',	mip_psu_1_24:'3200',	oca_1_24:'0',	total_tal_01:'4710',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'70',	ltip_rsu_3_26:'70',	mip_rsu_3_25:'720',	mip_psu_2_25:'3200',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'4060',	},
{	empleado:'RIOS LOPEZ ISABEL',	email:'irios@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'66',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'640',	mip_rsu_2_24:'640',	mip_psu_1_24:'2875',	oca_1_24:'0',	total_tal_01:'4221',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'67',	ltip_rsu_3_26:'67',	mip_rsu_3_25:'640',	mip_psu_2_25:'2875',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'3649',	},
{	empleado:'VELASCO OCEGUERA ALEJANDRO',	email:'avelasco@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'70',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'640',	mip_rsu_2_24:'640',	mip_psu_1_24:'2875',	oca_1_24:'0',	total_tal_01:'4225',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'70',	ltip_rsu_3_26:'70',	mip_rsu_3_25:'640',	mip_psu_2_25:'2875',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'3655',	},
{	empleado:'ARANALDE AGUILAR ALVAREZ ALFONSO ENRICO',	email:'aaranalde@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'86',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3326',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'87',	ltip_rsu_3_26:'87',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2914',	},
{	empleado:'SALAZAR FLORES MELISSA MICHELLE',	email:'mmsalazar@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'70',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'570',	mip_rsu_2_24:'570',	mip_psu_1_24:'2560',	oca_1_24:'0',	total_tal_01:'3770',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'70',	ltip_rsu_3_26:'70',	mip_rsu_3_25:'570',	mip_psu_2_25:'2560',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'3270',	},
{	empleado:'MIRANDA LUJAN JUAN PABLO',	email:'jmirandal@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'76',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'570',	mip_rsu_2_24:'570',	mip_psu_1_24:'2560',	oca_1_24:'0',	total_tal_01:'3776',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'77',	ltip_rsu_3_26:'77',	mip_rsu_3_25:'570',	mip_psu_2_25:'2560',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'3284',	},
{	empleado:'HERRERA CONTRERAS AUREA PATRICIA',	email:'pherrera@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'80',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'570',	mip_rsu_2_24:'570',	mip_psu_1_24:'2560',	oca_1_24:'0',	total_tal_01:'3780',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'80',	ltip_rsu_3_26:'80',	mip_rsu_3_25:'570',	mip_psu_2_25:'2560',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'3290',	},
{	empleado:'VIDAL MARTINEZ GUSTAVO',	email:'gvidal@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'70',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3310',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'70',	ltip_rsu_3_26:'70',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2880',	},
{	empleado:'LIZASO IVETTE',	email:'ilizaso@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'106',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3346',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'107',	ltip_rsu_3_26:'107',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2954',	},
{	empleado:'TAMAYO TORO ANDRES FELIPE',	email:'atamayo@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'80',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3320',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'80',	ltip_rsu_3_26:'80',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2900',	},
{	empleado:'MENDES DE OLIVEIRA ADENIL',	email:'amendes@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'84',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3324',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'83',	ltip_rsu_3_26:'83',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2906',	},
{	empleado:'REYES HERRERA ELIAS ESKANI JURHIAT',	email:'ereyes@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'76',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3316',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'77',	ltip_rsu_3_26:'77',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2894',	},
{	empleado:'BAUTISTA GUTIERREZ HECTOR AGUSTIN',	email:'hbautista@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'76',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3316',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'77',	ltip_rsu_3_26:'77',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2894',	},
{	empleado:'VALENCIA RODRIGUEZ GERARDO',	email:'gvalencia@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'70',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3310',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'70',	ltip_rsu_3_26:'70',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2880',	},
{	empleado:'MARQUEZ LIRA DIANA MELINA',	email:'dmarquez@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'70',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3310',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'70',	ltip_rsu_3_26:'70',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2880',	},
{	empleado:'COELLO VARGAS RUBEN',	email:'rcoello@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'74',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3314',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'73',	ltip_rsu_3_26:'73',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2886',	},
{	empleado:'ISLAS PADILLA LUIS',	email:'luislas@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'106',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3346',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'107',	ltip_rsu_3_26:'107',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2954',	},
{	empleado:'PAYAN GOMEZ ADOLFO MOISES',	email:'apayan@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'84',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3324',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'83',	ltip_rsu_3_26:'83',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2906',	},
{	empleado:'BRAVO SANSORES MARIO',	email:'mbravos@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'80',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3320',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'80',	ltip_rsu_3_26:'80',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2900',	},
{	empleado:'ZUMARRAGA CONTRERAS JUAN PABLO',	email:'jzumarraga@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'86',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3326',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'87',	ltip_rsu_3_26:'87',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2914',	},
{	empleado:'CRISTOBAL PULIDO HUGO',	email:'hcristobal@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'90',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3330',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'90',	ltip_rsu_3_26:'90',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2920',	},
{	empleado:'CANALES RUIZ JOSE EDUARDO',	email:'ecanales@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'66',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3306',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'67',	ltip_rsu_3_26:'67',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2874',	},
{	empleado:'RAMIREZ PEREZ ISRAEL',	email:'iramirez@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'66',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3306',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'67',	ltip_rsu_3_26:'67',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2874',	},
{	empleado:'TOLEDO TOVAR RAYMUNDO JAVIER',	email:'rtoledo@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'74',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3314',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'73',	ltip_rsu_3_26:'73',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2886',	},
{	empleado:'DIAZ INIESTRA SERGIO EDUARDO',	email:'sdiaz@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'74',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1934',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'73',	ltip_rsu_3_26:'73',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1716',	},
{	empleado:'PINEDA CHAZARO ZOILA ELENA',	email:'zpineda@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'64',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1924',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'63',	ltip_rsu_3_26:'63',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1696',	},
{	empleado:'CASTRO FOULKES ANDRES',	email:'acastrof@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'60',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1920',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'60',	ltip_rsu_3_26:'60',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1690',	},
{	empleado:'PENICHE BETANCOURT LUIS FERNANDO',	email:'lpeniche@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'74',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'860',	mip_rsu_2_24:'860',	mip_psu_1_24:'3835',	oca_1_24:'0',	total_tal_01:'5629',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'73',	ltip_rsu_3_26:'73',	mip_rsu_3_25:'860',	mip_psu_2_25:'3835',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'4841',	},
{	empleado:'SALIM SALOMON IVETTE',	email:'isalim@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'70',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3310',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'70',	ltip_rsu_3_26:'70',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2880',	},
{	empleado:'DE LA MORA CRUZ LUIS EDUARDO',	email:'lde@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'66',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1926',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'67',	ltip_rsu_3_26:'67',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1704',	},
{	empleado:'LOPEZ MIGUEZ ISRAEL',	email:'ilopezm@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'60',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'430',	mip_rsu_2_24:'430',	mip_psu_1_24:'1920',	oca_1_24:'0',	total_tal_01:'2840',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'60',	ltip_rsu_3_26:'60',	mip_rsu_3_25:'430',	mip_psu_2_25:'1920',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2470',	},
{	empleado:'ALBA ESCAMILLA ANDRES MAURICIO',	email:'aalba@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'70',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'500',	mip_rsu_2_24:'500',	mip_psu_1_24:'2240',	oca_1_24:'0',	total_tal_01:'3310',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'70',	ltip_rsu_3_26:'70',	mip_rsu_3_25:'500',	mip_psu_2_25:'2240',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2880',	},
{	empleado:'PINEDA BADILLO ALFREDO',	email:'apineda@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'56',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1916',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'57',	ltip_rsu_3_26:'57',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1684',	},
{	empleado:'BANOS ENCISO ERIKA',	email:'ebanos@aeromexico.com',	bloque:'2',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'64',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1924',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'63',	ltip_rsu_3_26:'63',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1696',	},
{	empleado:'ENRIQUEZ ORTIZ OSCAR',	email:'oenriquezo@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'VELAZQUEZ ALVAREZ MARIA JOSE',	email:'mjvelazquez@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'VILLARREAL REYNAUD MARIANO',	email:'mvillarrealr@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'ALCOCER DELANO JOSE',	email:'jalcocer@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'ESPANA MENDIVIL FRANCISCO JAVIER',	email:'fespana@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'DIAZ GUADARRAMA MARICELA',	email:'mardiazg@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'GUERRERO VARGAS FRANCO',	email:'fguerrero@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'RODRIGUEZ RUIZ BERTHA VERONICA',	email:'brodriguez@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'TALAVERA HERNANDEZ ALEJANDRO',	email:'atalaverah@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'HERNANDEZ VILLA GADIEL',	email:'ghernandezv@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'MARTINSON MICHELE DENISE',	email:'mmartinson@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'ESQUEDA RUIZ FERNANDO',	email:'fesqueda@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'VAZQUEZ MELLADO GAVIDIA MARIA GABRIELA',	email:'gvazquezmellado@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'MULEIRO ALVAREZ FELIPE',	email:'fmuleiro@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'430',	mip_rsu_2_24:'430',	mip_psu_1_24:'1920',	oca_1_24:'0',	total_tal_01:'2780',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'430',	mip_psu_2_25:'1920',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2350',	},
{	empleado:'GARMENDIA PULIDO ALBERTO',	email:'agarmendia@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'ALCALA CORTES ALBERTO',	email:'aalcala@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'360',	mip_rsu_2_24:'360',	mip_psu_1_24:'1605',	oca_1_24:'0',	total_tal_01:'2325',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'360',	mip_psu_2_25:'1605',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1965',	},
{	empleado:'GROBEISEN DUQUE GALIA',	email:'ggrobeisen@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'360',	mip_rsu_2_24:'360',	mip_psu_1_24:'1605',	oca_1_24:'0',	total_tal_01:'2325',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'360',	mip_psu_2_25:'1605',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1965',	},
{	empleado:'ALVAREZ CASTELLANOS ROBERT',	email:'ralvarez@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'360',	mip_rsu_2_24:'360',	mip_psu_1_24:'1605',	oca_1_24:'0',	total_tal_01:'2325',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'360',	mip_psu_2_25:'1605',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1965',	},
{	empleado:'YEPEZ DELGADO MAHETZI DESIREE',	email:'myepez@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'HERNANDEZ MOCTEZUMA JOSE GUSTAVO',	email:'jghernandezm@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'ESPINOSA VERA JORDANA RUTHVE',	email:'jrespinosa@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'430',	mip_rsu_2_24:'430',	mip_psu_1_24:'1920',	oca_1_24:'0',	total_tal_01:'2780',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'430',	mip_psu_2_25:'1920',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2350',	},
{	empleado:'ROCCATTI HERNANDEZ JOSE FRANCISCO',	email:'jroccatti@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'REYES ELIZONDO ALEJANDRA',	email:'areyese@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'360',	mip_rsu_2_24:'360',	mip_psu_1_24:'1605',	oca_1_24:'0',	total_tal_01:'2325',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'360',	mip_psu_2_25:'1605',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1965',	},
{	empleado:'MATA VAQUERA HECTOR ORLANDO',	email:'hmata@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'BARROSO URIBE MARCO AURELIO',	email:'mbarroso@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'CASTRO MENDOZA GONZALO',	email:'gcastro@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'PARADIS BRAVO MARIA DOLORES',	email:'mparadis@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'CANO CALLEJAS LUCERO',	email:'lucano@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'GARZA GUZMAN MARIANA',	email:'mgarza@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'360',	mip_rsu_2_24:'360',	mip_psu_1_24:'1605',	oca_1_24:'0',	total_tal_01:'2325',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'360',	mip_psu_2_25:'1605',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1965',	},
{	empleado:'GOMEZ RODRIGUEZ IRAN',	email:'igomez@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'360',	mip_rsu_2_24:'360',	mip_psu_1_24:'1605',	oca_1_24:'0',	total_tal_01:'2325',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'360',	mip_psu_2_25:'1605',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1965',	},
{	empleado:'MATADAMAS CANSECO GUILLERMO RENE',	email:'gmatadamas@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'HERNANDEZ GUEVARA NANCY DOLORES',	email:'ndhernandez@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'SEGURA CASTILLO HECTOR GUADALUPE',	email:'hsegura@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'GOMEZ RODRIGUEZ SANDRA',	email:'sgomez@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'BYRD MARTINEZ ARTURO',	email:'abyrd@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'VELAZQUEZ JOSE LUIS',	email:'jvelazquez@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'HERRERA FLORES JOSE GPE FERNANDO',	email:'fherrera@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'ESPINOZA CASTRO VICTOR MANUEL',	email:'vespinozac@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'FERNANDEZ ANTONIO NACIR',	email:'nafernandez@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'GONZALEZ GONZALEZ SALAS ANDREA',	email:'angonzalezg@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'SERRANO GAVINO MARIA ANGELINA',	email:'maserrano@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'MARTINEZ MORENO LUISA JOSEFINA',	email:'lmartinezmo@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'ANDRADE ZACARIAS SALVADOR',	email:'sandrade@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'AGUILAR AYALA EDGAR',	email:'eaguilara@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'RAYMUNDO RUIZ FRANCISCO',	email:'fraymundo@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'GUERRERO GARCIA LUCERO NOHEMI',	email:'lnguerrero@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'GRANADOS LOPEZ ARELY JAQUELINE',	email:'agranados@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'LOPEZ DE ARKOS MARTINEZ ERNESTO JESUS',	email:'elopezdearkos@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'TAVIZON CEBALLOS ADOLFO SALVADOR',	email:'atavizon@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'430',	mip_rsu_2_24:'430',	mip_psu_1_24:'1920',	oca_1_24:'0',	total_tal_01:'2780',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'430',	mip_psu_2_25:'1920',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'2350',	},
{	empleado:'MORON CALVILLO VALENTIN',	email:'vmoron@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'HERNANDEZ MENDIOLA MARIA DEL PILAR',	email:'mhernandezm@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'GALVAN ALCALA DANIELA ISAURA',	email:'dgalvan@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'PICHARDO ROMERO ROMAN',	email:'rpichardor@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'MERCADO RESENDIZ GISELA NOEMI',	email:'gmercado@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'SANCHEZ GARCIA ADRIANA',	email:'asanchezg@aeromexico.com',	bloque:'3',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'290',	mip_rsu_2_24:'290',	mip_psu_1_24:'1280',	oca_1_24:'0',	total_tal_01:'1860',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'290',	mip_psu_2_25:'1280',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1570',	},
{	empleado:'RAMOS RUIZ EMILIO',	email:'eramosru@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'434',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'434',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'433',	ltip_rsu_3_26:'433',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'866',	},
{	empleado:'CONVALIA ZELADA DIEGO EDUARDO',	email:'dconvalia@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'546',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'546',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'547',	ltip_rsu_3_26:'547',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'1094',	},
{	empleado:'MARTINEZ SOTO GERARDO',	email:'gmartinezs@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'374',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'374',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'373',	ltip_rsu_3_26:'373',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'746',	},
{	empleado:'FARIAS CRUZADO KAREN ALEJANDRA',	email:'kfarias@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'320',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'320',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'320',	ltip_rsu_3_26:'320',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'640',	},
{	empleado:'MORENO LAGOS MANUEL ALEJANDRO',	email:'mmorenol@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'370',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'370',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'370',	ltip_rsu_3_26:'370',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'740',	},
{	empleado:'ALMANZA GALVEZ JUAN CARLOS',	email:'jalmanzag@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'294',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'294',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'293',	ltip_rsu_3_26:'293',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'586',	},
{	empleado:'EDWARDS MORENO MORGAN VALENTINA',	email:'medwards@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'354',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'354',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'353',	ltip_rsu_3_26:'353',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'706',	},
{	empleado:'SOLARES Y SOLARES ADAN ANTONIO',	email:'asolaresy@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'410',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'410',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'410',	ltip_rsu_3_26:'410',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'820',	},
{	empleado:'RAMIREZ VILCHIS MARIO ALBERTO',	email:'mramirezvi@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'56',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'56',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'57',	ltip_rsu_3_26:'57',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'114',	},
{	empleado:'MEDINA GONZALEZ LUCERO ANGELICA',	email:'lmedinag@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'60',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'60',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'60',	ltip_rsu_3_26:'60',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'120',	},
{	empleado:'SANCHEZ OBREGON LETICIA',	email:'lsanchezo@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'86',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'86',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'87',	ltip_rsu_3_26:'87',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'174',	},
{	empleado:'SALDANA SANCHEZ LUIS ANTONIO',	email:'lsaldanas@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'76',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'76',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'77',	ltip_rsu_3_26:'77',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'154',	},
{	empleado:'MORETT GOMEZ TAGLE ROSA MARIA',	email:'rgomezt@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'64',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'64',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'63',	ltip_rsu_3_26:'63',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'126',	},
{	empleado:'AVILA REYES MARCO ROMAN',	email:'mavilar@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'76',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'76',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'77',	ltip_rsu_3_26:'77',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'154',	},
{	empleado:'VIVANCO DOMINGUEZ MARIO ENRIQUE',	email:'mvivanco@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'66',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'66',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'67',	ltip_rsu_3_26:'67',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'134',	},
{	empleado:'MAYA DESDIER CARLOS',	email:'cmaya@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'76',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'76',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'77',	ltip_rsu_3_26:'77',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'154',	},
{	empleado:'MORALES MOLINA CARLOS DOMENICK',	email:'dmoralesm@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'64',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'64',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'63',	ltip_rsu_3_26:'63',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'126',	},
{	empleado:'FIGUEROA PEREZ MONSERRAT',	email:'mfigueroap@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'100',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'100',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'100',	ltip_rsu_3_26:'100',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'200',	},
{	empleado:'CUCCARESE ALEJANDRO JAVIER',	email:'acuccarese@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'56',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'56',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'57',	ltip_rsu_3_26:'57',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'114',	},
{	empleado:'MURADAS RUIZ JUAN CARLOS',	email:'jmuradas@aeromexico.com',	bloque:'4',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'86',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'0',	total_tal_01:'86',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'87',	ltip_rsu_3_26:'87',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'0',	oca_3_24:'0',	total_anl_02:'174',	},
{	empleado:'JOVEL MUNGUIA CARLOS ROBERTO',	email:'cjovel@aeromexico.com',	bloque:'5',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'1436',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'14197',	total_tal_01:'15633',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'1437',	ltip_rsu_3_26:'1437',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'14197',	oca_3_24:'14196',	total_anl_02:'31267',	},
{	empleado:'MORGAN JEFFREY WALKER',	email:'jmorgan@aeromexico.com',	bloque:'6',	ltip_rsu_1_23:'0',	ltip_rsu_1_24:'0',	ltip_rsu_2_24:'0',	mip_rsu_1_23:'0',	mip_rsu_2_24:'0',	mip_psu_1_24:'0',	oca_1_24:'21295',	total_tal_01:'21295',	ltip_rsu_3_25:'0',	ltip_rsu_2_25:'0',	ltip_rsu_3_26:'0',	mip_rsu_3_25:'0',	mip_psu_2_25:'0',	oca_2_24:'21295',	oca_3_24:'0',	total_anl_02:'21295',	},
];

const apValid = () => {
	// Obtener el correo del localStorage
	const storedEmail = localStorage.getItem('email');

	// Verificar si el correo está en la lista de colaboradores
	return fakeStockER.some(colaborador => colaborador.email === storedEmail);
};

const AccionesPersonales = () => {

	const [filteredColaboradores, setFilteredColaboradores] = useState([]);
	const [isValid, setIsValid] = useState(false);
	

	useEffect(() => {
		window.scrollTo(0, 0);

		const storedEmail = localStorage.getItem('email');
		const filtered = fakeStockER.filter(colaborador => colaborador.email === storedEmail);
		setIsValid(apValid());
		setFilteredColaboradores(filtered);
	}, []);

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const formatNumberString = (numberString) => {
		if (typeof numberString !== 'string') {
		  return '';
		}
		return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	  };
	  

	const colaboradorNombres = filteredColaboradores.map((colaborador) => colaborador.nombre).join(", ");
	const bloque = filteredColaboradores.map((colaborador) => colaborador.bloque);

	const ltipValues = filteredColaboradores.map((colaborador) => ({
		ltip_rsu_1_23: formatNumberString(colaborador.ltip_rsu_1_23),
		ltip_rsu_1_24: formatNumberString(colaborador.ltip_rsu_1_24),
		ltip_rsu_2_24: formatNumberString(colaborador.ltip_rsu_2_24),
	}));

	const mipValues = filteredColaboradores.map((colaborador) => ({
		mip_rsu_1_23: formatNumberString(colaborador.mip_rsu_1_23),
		mip_rsu_2_24: formatNumberString(colaborador.mip_rsu_2_24),
		mip_psu_1_24: formatNumberString(colaborador.mip_psu_1_24),
	}));

	const ocaValues = filteredColaboradores.map((colaborador) => ({
		oca_1_24: formatNumberString(colaborador.oca_1_24),
		oca_2_24: formatNumberString(colaborador.oca_2_24),
		oca_3_24: formatNumberString(colaborador.oca_3_24),
	}));

	const totalTalValues = filteredColaboradores.map((colaborador) => formatNumberString(colaborador.total_tal_01));
	const totalAnlValues = filteredColaboradores.map((colaborador) => formatNumberString(colaborador.total_anl_02));

	if (!isValid) {
        return <p>Usuario no autorizado.</p>;
    }

	return (

			<motion.div
			key="page-procesos-administrativos"
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 50 }}
			className="full-container">
			{/* <Arriba /> */}
			<div className="content-holder-talent">
				<header className="talent-header relative py-40">
					<div className="absolute left-1/2 top-1/2 z-20 flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center">
						<h1 className="mb-1 text-center text-5xl font-bold leading-none text-white opacity-100">
							{i18next.language === "es" ? "Incentivos en acciones" : "Stock incentives"}
						</h1>
						<h2 class="text-center text-2xl font-bold leading-none text-white opacity-100">{i18next.language === "es" ? "Información a julio 2024" : "Information until July 2024"}</h2>
					</div>
					<div className="absolute left-0 top-0 z-10 h-full w-full bg-amDarkBlue opacity-75"></div>
					<img
						src={Banner}
						alt="Talent Planning"
						className="absolute left-0 top-0 z-0 h-full w-full object-cover"
					/>
				</header>
				<section className="py-16" style={{ background: "#F6F8F9" }}>
					<div class="container mx-auto flex flex-col items-center px-10 lg:px-16">
							{/* INICIA TABLA PARA ACCIONES LIBERADAS  */}
							<div class="">
								<p class="rewards_title text-left" style={{ fontSize: "1.5rem" }}>ACCIONES LIBERADAS</p>
								<div class="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
									<table class="min-w-full text-left text-sm whitespace-nowrap">
										{bloque == "1" || bloque == "2" || bloque == "4" || bloque == "5" ? (
											<>
												<thead class="uppercase tracking-wider bg-[#035cf7] text-white">
													<tr>
														<th scope="col" class="px-6 py-2 text-white" colSpan={3}><p>Incentivo a largo plazo (LTIP)</p></th>
													</tr>
												</thead>
												<tbody>
													<tr class="">
														<th scope="row" class="px-6 py-4">
															<p>1/3 en acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un tercio cada año.</p>
														</th>
														<td class="px-6 py-4 border-b"  >
															<div class="space-y-2">
																{bloque == "1" ? (<div class="py-2">2022 RSU Parte 1 - 2023 </div>) : null}
																{bloque == "1" ? (<div class="py-2">2022 RSU Parte 2 - 2024</div>) : null}
																<div class="py-2">2023 RSU Parte 1 - 2024</div>
															</div>
														</td>
														{ltipValues.map((values, index) => (
															<td key={index} className="px-6 py-4 border-b" rowSpan="3">
																<div className="space-y-2">
																	{bloque == "1" ? (<div className="py-2 text-right">{values.ltip_rsu_1_23}</div>) : null}
																	{bloque == "1" ? (<div className="py-2 text-right">{values.ltip_rsu_2_24}</div>) : null}
																	<div className="py-2 text-right">{values.ltip_rsu_1_24}</div>
																</div>
															</td>
														))}
													</tr>
												</tbody>
											</>
										) : null}
										{bloque == "1" || bloque == "2" || bloque == "3" ? (
											<>
												<thead class="uppercase tracking-wider bg-[#035cf7] text-white">
													<tr><th scope="col" class="px-6 py-2 text-white" colSpan={3}><p>Management Incentive Plan (MIP)</p></th></tr>
												</thead>
												<tbody>
													<tr class="">
														<th scope="row" class="px-6 py-4">
															<p>40% en acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un tercio cada año.</p>
															<p>60% en acciones sujetas al desempeño del negocio (PSU), con un periodo de liberación del 50%<br /> en el segundo año y 50% en el tercer año posterior a la asignación.</p>
														</th>
														<td class="px-6 py-4 border-b" rowspan="3">
															<div class="space-y-2">
																<div class="py-2">RSU Parte 1 - 2023</div>
																<div class="py-2">RSU Parte 2 - 2024</div>
																<div class="py-2">PSU Parte 1 - 2024</div>
															</div>
														</td>
														{mipValues.map((values, index) => (
															<td key={index} className="px-6 py-4 border-b" rowSpan="3">
																<div className="space-y-2">
																	<div className="py-2 text-right">{values.mip_rsu_1_23}</div>
																	<div className="py-2 text-right">{values.mip_rsu_2_24}</div>
																	<div className="py-2 text-right">{values.mip_psu_1_24}</div>
																</div>
															</td>
														))}
													</tr>
												</tbody>
											</>
										) : null}
										{bloque == "5" || bloque == "6" ? (
											<>
												<thead class="uppercase tracking-wider bg-[#035cf7] text-white">
													<tr><th scope="col" class="px-6 py-2 text-white" colSpan={3}><p>Off Cycle Award</p></th></tr>
												</thead>
												<tbody>
													<tr class="">
														<th scope="row" class="px-6 py-4">
														{bloque == "5" ? (
																<p>Acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un tercio cada año.</p>
															) : <p>Acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un 50% cada año.</p>}
														</th>
														<td class="px-6 py-4 border-b">
															<div class="space-y-2">
																<div class="py-2">RSU Parte 1 - 2024</div>
															</div>
														</td>
														{ocaValues.map((values, index) => (
															<td key={index} className="px-6 py-4 border-b">
																<div className="space-y-2">
																	<div className="py-2 text-right">{values.oca_1_24}</div>
																</div>
															</td>
														))}
													</tr>
												</tbody>
											</>
										) : null}

										<tfoot class="border-t-2 dark:border-neutral-600">
											<tr>
												<th class="px-6 py-4"></th>
												<td class="px-6 py-4"><strong>Total Acciones Liberadas</strong></td>
												{totalTalValues.map((value, index) => (
													<td key={index} className="px-6 py-4 text-right"><strong>{value}</strong></td>
												))}
											</tr>
										</tfoot>
									</table>
								</div>
							</div>
							{/* TERMINA TABLA PARA ACCIONES LIBERADAS  */}
							{/* INICIA TABLA PARA ACCIONES NOOOOOOO LIBERADAS  */}
							<div class="">
								<p class="rewards_title text-left" style={{ fontSize: "1.5rem" }}>ACCIONES RESTRINGIDAS</p>
								<div class="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
									<table class="min-w-full text-left text-sm whitespace-nowrap">
										{bloque == "1" || bloque == "2" || bloque == "4" || bloque == "5" ? (
											<>
												<thead class="uppercase tracking-wider bg-[#8d807a] text-white">
													<tr>
														<th scope="col" class="px-6 py-2 text-white" colSpan={3}><p>Incentivo a largo plazo (LTIP)</p></th>
													</tr>
												</thead>
												<tbody>
													<tr class="">
														<th scope="row" class="px-6 py-4">
															<p>1/3 en acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un tercio cada año.</p>
														</th>
														<td class="px-6 py-4 border-b" rowspan="3">
															<div class="space-y-2">
																{bloque == "1" ? (<div class="py-2">2022 RSU Parte 3 - 2025</div>) : null}
																<div class="py-2">2023 RSU Parte 2 - 2025</div>
																<div class="py-2">2023 RSU Parte 3 - 2026</div>
															</div>
														</td>
														{filteredColaboradores.map((colaborador, index) => (
															<td class="px-6 py-4 border-b" rowspan="3">
																<div class="space-y-2">
																	{bloque == "1" ? (<div class="py-2 text-right">{formatNumberString(colaborador.ltip_rsu_3_25)}</div>) : null}
																	<div class="py-2 text-right">{formatNumberString(colaborador.ltip_rsu_2_25)}</div>
																	<div class="py-2 text-right">{formatNumberString(colaborador.ltip_rsu_3_26)}</div>
																</div>
															</td>
														))}
													</tr>
												</tbody>
											</>
										) : null}
										{bloque == "1" || bloque == "2" || bloque == "3" ? (
											<>

												<thead class="uppercase tracking-wider bg-[#8d807a] text-white">
													<tr>
														<th scope="col" class="px-6 py-2 text-white" colSpan={3}><p>Management Incentive Plan (MIP)</p></th>
													</tr>
												</thead>
												<tbody>
													<tr class="">
														<th scope="row" class="px-6 py-4">
															<p>40% en acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un tercio cada año.</p>
															<p>60% en acciones sujetas al desempeño del negocio (PSU), con un periodo de liberación del 50%<br /> en el segundo año y 50% en el tercer año posterior a la asignación.</p>
														</th>
														<td class="px-6 py-4 border-b" rowspan="2">
															<div class="space-y-2">
																<div class="py-2">RSU Parte 3 - 2025</div>
																<div class="py-2">PSU Parte 2 - 2025</div>
															</div>
														</td>
														{filteredColaboradores.map((colaborador, index) => (
															<td class="px-6 py-4 border-b" rowspan="2">
																<div class="space-y-2">
																	<div class="py-2 text-right">{formatNumberString(colaborador.mip_rsu_3_25)}</div>
																	<div class="py-2 text-right">{formatNumberString(colaborador.mip_psu_2_25)}</div>
																</div>
															</td>
														))}
													</tr>
												</tbody>
											</>
										) : null}
										{bloque == "5" || bloque == "6" ? (
											<>
												<thead class="uppercase tracking-wider bg-[#8d807a] text-white">
													<tr><th scope="col" class="px-6 py-2 text-white" colSpan={3}><p>Off Cycle Award</p></th></tr>
												</thead>
												<tbody>
													<tr class="">
														<th scope="row" class="px-6 py-4">
															{bloque == "5" ? (
																<p>Acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un tercio cada año.</p>
															) : <p>Acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un 50% cada año.</p>}
															
															
														</th>
														<td class="px-6 py-4 border-b">
															<div class="space-y-2">
																{bloque == "5" || bloque == "6" ? (<div class="py-2">RSU Parte 2 - 2025</div>) : null}
																{bloque == "5" ? (<div class="py-2">RSU Parte 3 - 2026</div>) : null}
															</div>
														</td>
														{ocaValues.map((values, index) => (
															<td key={index} className="px-6 py-4 border-b">
																<div className="space-y-2">
																	<div className="py-2 text-right">{values.oca_2_24}</div>
																	{bloque == "5" ? (<div className="py-2 text-right">{values.oca_3_24}</div>) : null}
																</div>
															</td>
														))}
													</tr>
												</tbody>
											</>
										) : null}
										<tfoot class="border-t-2 dark:border-neutral-600">
											<tr>
												<th class="px-6 py-4"></th>
												<td class="px-6 py-4"><strong>Total Acciones No Liberadas</strong></td>
												{filteredColaboradores.map((colaborador, index) => (
													<td class="px-6 py-4 text-right"><strong>{formatNumberString(colaborador.total_anl_02)}</strong></td>
												))}
											</tr>
										</tfoot>
									</table>
								</div>
							</div>
							{/* INICIA TABLA PARA ACCIONES NOOOOOOO LIBERADAS  */}
						</div>
						<div class="container mx-auto flex flex-col items-center px-10 lg:px-16">
						<p>Este documento tiene fines informativos y está destinado únicamente para su visualización. No constituye un documento oficial.</p>
						<p>Si tienes alguna duda, contacta a <a href="mailto:rigutierrez@aeromexico.com">rigutierrez@aeromexico.com</a>, <a href="mailto:mmsalazar@aeromexico.com">mmsalazar@aeromexico.com</a> o <a href="mailto:lsaldanas@aeromexico.com">lsaldanas@aeromexico.com</a></p>
						<p>&nbsp;</p>
						<p>Nota: Si no puedes ver la tabla completa, utiliza el cursor para desplazarte hacia la derecha.</p> 
						</div>

				</section>
			</div>
		</motion.div>
	);
};

export default AccionesPersonales;