import React from "react";
import { ContactoStyle } from "../styles/Styled";
import i18next from 'i18next';
 

function Contacto() {
	const isSpanish = i18next.language === 'es';
	return (
		<ContactoStyle>
			<div className="">
				<div className="row"  >
					<article  className="left col-12 col-md-6">
						<div  style={{marginLeft:'6%'}}>
						<span className="single-line" >
							<div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M20.487,17.14l-4.065-3.696c-0.399-0.363-1.015-0.344-1.391,0.043l-2.393,2.461c-0.576-0.11-1.734-0.471-2.926-1.66	c-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394c0.388-0.376,0.406-0.991,0.043-1.391L6.859,3.513	c-0.363-0.401-0.98-0.439-1.391-0.087l-2.17,1.861C3.125,5.461,3.022,5.691,3.008,5.936c-0.015,0.25-0.301,6.172,4.291,10.766	C11.305,20.707,16.323,21,17.705,21c0.202,0,0.326-0.006,0.359-0.008c0.245-0.014,0.475-0.117,0.648-0.291l1.86-2.171	C20.926,18.119,20.887,17.503,20.487,17.14z" />
								</svg>
								<p>{isSpanish?"Comunícate al:":"Communicate to:"}</p>
							</div>
							<a href="tel:5514286959">55 1428 6959 </a> <strong>o</strong> 
							<a href="tel:56 1149 4357"> 56 1149 4357</a>
						</span>
						<span className="single-line">
							<div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
								</svg>

								<p>{isSpanish?"Envía un correo a:":"Send an email to:"}</p>
							</div>
						</span>
						<a
							href="ygonzalezo@aeromexico.com"
							className="big-email"
						>
							ygonzalezo@aeromexico.com
						</a>
						<br/>
						<a
							href="smarquezp@aeromexico.com"
							className="big-email"
						>
							smarquezp@aeromexico.com
						</a>
						</div>
						<div className="fake-border"></div>
					</article>
					<article className="right col-12 col-md-6">
						<p className="no-margin">{isSpanish?"Si eres tripulante:":"If you are a crew member:"}</p>
						<span className="single-line">
							<div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M20.487,17.14l-4.065-3.696c-0.399-0.363-1.015-0.344-1.391,0.043l-2.393,2.461c-0.576-0.11-1.734-0.471-2.926-1.66	c-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394c0.388-0.376,0.406-0.991,0.043-1.391L6.859,3.513	c-0.363-0.401-0.98-0.439-1.391-0.087l-2.17,1.861C3.125,5.461,3.022,5.691,3.008,5.936c-0.015,0.25-0.301,6.172,4.291,10.766	C11.305,20.707,16.323,21,17.705,21c0.202,0,0.326-0.006,0.359-0.008c0.245-0.014,0.475-0.117,0.648-0.291l1.86-2.171	C20.926,18.119,20.887,17.503,20.487,17.14z" />
								</svg>
								<p>{isSpanish?"Llama al:":"Call To:"}</p>
							</div>
							<a href="tel:5551063486">55 5106 3486</a>
							<p className="word-breaker-nom"> {isSpanish?"o:":"or:"} </p>
							<a href="tel:5591854226">55 9185 4226</a>
						</span>
						<span className="single-line">
							<div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
								</svg>

								<p>{isSpanish?"O envía un correo a:":"Or send an email to:"}</p>
							</div>
						</span>
						<a
							href="mailto:dgonzalezc@aeromexico.com"
							className="big-email"
						>
							dgonzalezc@aeromexico.com
						</a>
						<a
							href="mailto:evallea@aeromexico.com"
							className="big-email"
						>
							evallea@aeromexico.com
						</a>
					</article>
				</div>
			</div>
		</ContactoStyle>
	);
}

export default Contacto;
