import React, { useContext } from 'react';
import { ReactComponent as MiAM } from './assets/logo-aeromexico.svg';
import NavItem from './NavItem';
import i18next from 'i18next';

function NavBar({ allCats, setCategory, category }) {
	// const { category, setCategory } = useContext(CategoryContext);
	// const allCats = JSON.parse(localStorage.getItem('allCats'));
	return (
		<nav
			className="navigation-bazar md:w-navbar left-0 top-0 w-full border-b border-black bg-white dark:bg-black md:h-screen md:overflow-y-auto md:border-r md:border-b-0 md:border-gray-700"
			style={{ zIndex: '999' }}
		>
			{/* <div className="sticky top-0 z-50 flex flex-col border-b border-gray-300 bg-white px-8 dark:border-gray-700 dark:bg-black">
				<MiAM className="my-4 h-6 w-auto md:my-6 md:h-auto md:w-full" />
			</div> */}
			<ul className="flex flex-col items-center py-6 px-6 md:items-start">
				<li className="w-auto px-4 text-xs uppercase text-gray-400 md:mb-4 md:w-full">
					{ i18next.language === 'es'? 'Categorías':'Categories' }
				</li>
				<NavItem
					title={i18next.language === 'es'?'Todos':'All'}
					selected={category === 'todos' ? true : false}
					handleClick={() => setCategory('todos')}
				>
					<path
						fill="currentColor"
						d="M7 7H9V9H7V7zM11 7H13V9H11V7zM17 7H15V9H17V7zM7 11H9V13H7V11zM13 11H11V13H13V11zM15 11H17V13H15V11zM9 15H7V17H9V15zM11 15H13V17H11V15zM17 15H15V17H17V15z"
					/>
				</NavItem>
				{allCats.indexOf('Accesorios y Ropa') !== -1 && (
					<NavItem
						title="Accesorios y Ropa"
						selected={
							category === 'Accesorios y Ropa' ? true : false
						}
						handleClick={() => setCategory('Accesorios y Ropa')}
					>
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M9 3a3 3 0 0 0 6 0h6a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-2.001L19 20a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1l-.001-8.001L3 12a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h6zm11 1.999h-3.417l-.017.041a5.002 5.002 0 0 1-4.35 2.955L12 8a5.001 5.001 0 0 1-4.566-2.96L7.416 5H4v5l2.999-.001V19H17l-.001-9L20 9.999v-5z" />
					</NavItem>
				)}
				{allCats.indexOf('Belleza y Salud') !== -1 && (
					<NavItem
						title="Belleza y Salud"
						selected={category === 'Belleza y Salud' ? true : false}
						handleClick={() => setCategory('Belleza y Salud')}
					>
						<path d="M19.649 5.286L14 8.548 14 2.025 10 2.025 10 8.548 4.351 5.286 2.351 8.751 7.999 12.012 2.351 15.273 4.351 18.738 10 15.477 10 22 14 22 14 15.477 19.649 18.738 21.649 15.273 16.001 12.012 21.649 8.751z" />
					</NavItem>
				)}
				{allCats.indexOf('Calzado') !== -1 && (
					<NavItem
						title="Calzado"
						selected={category === 'Calzado' ? true : false}
						handleClick={() => setCategory('Calzado')}
					>
						<path fill="none" d="M0 0h24v24H0z" />
						<path d="M4 18h5.5v1.25a2.75 2.75 0 1 1-5.5 0V18zm4.058-4l.045-.132C8.87 11.762 9 11.37 9 11c0-.75-.203-1.643-.528-2.273C8.23 8.257 8.06 8.12 8 8.12 6.72 8.12 5.5 9.484 5.5 11c0 .959.075 1.773.227 2.758l.038.242h2.293zM8 6.12c2 0 3 2.88 3 4.88 0 1-.5 2-1 3.5L9.5 16H4c0-1-.5-2.5-.5-5S5.498 6.12 8 6.12zm12.054 7.978l-.217 1.231a2.75 2.75 0 0 1-5.417-.955l.218-1.23 5.416.954zm-1.05-4.246c.165-.5.301-.895.303-.9.202-.658.361-1.303.485-2.008.263-1.492-.702-3.047-1.962-3.27-.059-.01-.25.095-.57.515-.43.565-.784 1.41-.915 2.147-.058.33-.049.405.27 2.263.045.256.082.486.116.717l.02.138 2.254.398zm-.826-8.147c2.464.434 4.018 3.124 3.584 5.586-.434 2.463-1.187 3.853-1.36 4.838l-5.417-.955-.232-1.564c-.232-1.564-.55-2.636-.377-3.62.347-1.97 1.832-4.632 3.802-4.285z" />
					</NavItem>
				)}
				{allCats.indexOf('Comida y Postres') !== -1 && (
					<NavItem
						title="Comida y Postres"
						selected={
							category === 'Comida y Postres' ? true : false
						}
						handleClick={() => setCategory('Comida y Postres')}
					>
						<path d="M11 9H9V2H7v7H5V2H3v7c0 2.12 1.66 3.84 3.75 3.97V22h2.5v-9.03C11.34 12.84 13 11.12 13 9V2h-2v7zm5-3v8h2.5v8H21V2c-2.76 0-5 2.24-5 4z" />
					</NavItem>
				)}
				{allCats.indexOf('Electrónicos') !== -1 && (
					<NavItem
						title="Electrónicos"
						selected={category === 'Electrónicos' ? true : false}
						handleClick={() => setCategory('Electrónicos')}
					>
						<path
							fill="currentColor"
							fillRule="evenodd"
							d="M6 4H18C18.5523 4 19 4.44772 19 5V8H5V5C5 4.44772 5.44771 4 6 4ZM19 19V10H5V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19ZM3 5C3 3.34315 4.34315 2 6 2H18C19.6569 2 21 3.34315 21 5V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V5ZM7 5C6.44772 5 6 5.44772 6 6C6 6.55228 6.44772 7 7 7H9C9.55228 7 10 6.55228 10 6C10 5.44772 9.55228 5 9 5H7ZM14 7C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5C13.4477 5 13 5.44772 13 6C13 6.55228 13.4477 7 14 7ZM18 6C18 6.55228 17.5523 7 17 7C16.4477 7 16 6.55228 16 6C16 5.44772 16.4477 5 17 5C17.5523 5 18 5.44772 18 6ZM14 15C14 16.1046 13.1046 17 12 17C10.8954 17 10 16.1046 10 15C10 13.8954 10.8954 13 12 13C13.1046 13 14 13.8954 14 15ZM16 15C16 17.2091 14.2091 19 12 19C9.79086 19 8 17.2091 8 15C8 12.7909 9.79086 11 12 11C14.2091 11 16 12.7909 16 15Z"
							clipRule="evenodd"
						/>
					</NavItem>
				)}
				{allCats.indexOf('Espectáculos') !== -1 && (
					<NavItem
						title="Espectáculos"
						selected={category === 'Espectáculos' ? true : false}
						handleClick={() => setCategory('Espectáculos')}
					>
						<path d="M18,11c0-0.959-0.68-1.761-1.581-1.954C16.779,8.445,17,7.75,17,7c0-2.206-1.794-4-4-4c-1.517,0-2.821,0.857-3.5,2.104 C8.821,3.857,7.517,3,6,3C3.794,3,2,4.794,2,7c0,0.902,0.312,1.727,0.817,2.396C2.324,9.761,2,10.342,2,11v8c0,1.103,0.897,2,2,2 h12c1.103,0,2-0.897,2-2v-2.638l4,2v-7l-4,2V11z M13,5c1.103,0,2,0.897,2,2s-0.897,2-2,2s-2-0.897-2-2S11.897,5,13,5z M6,5 c1.103,0,2,0.897,2,2S7.103,9,6,9S4,8.103,4,7S4.897,5,6,5z M4,19v-8h12l0.002,8H4z" />
					</NavItem>
				)}
				{allCats.indexOf('Joyería y Relojes') !== -1 && (
					<NavItem
						title="Joyería y Relojes"
						size="6"
						selected={
							category === 'Joyería y Relojes' ? true : false
						}
						handleClick={() => setCategory('Joyería y Relojes')}
					>
						<path
							fillRule="evenodd"
							d="M6 16.333v-1.86A5.985 5.985 0 014 10c0-1.777.772-3.374 2-4.472V3.667C6 2.747 6.746 2 7.667 2h4.666C13.253 2 14 2.746 14 3.667v1.86A5.985 5.985 0 0116 10a5.985 5.985 0 01-2 4.472v1.861c0 .92-.746 1.667-1.667 1.667H7.667C6.747 18 6 17.254 6 16.333zM15 10a5 5 0 10-10 0 5 5 0 0010 0z"
							clipRule="evenodd"
						/>
						<rect width="1" height="2" x="15.5" y="9" rx=".5" />
						<path
							fillRule="evenodd"
							d="M10 6.5a.5.5 0 01.5.5v3a.5.5 0 01-.5.5H8a.5.5 0 010-1h1.5V7a.5.5 0 01.5-.5z"
							clipRule="evenodd"
						/>
					</NavItem>
				)}
				{allCats.indexOf('Juguetes') !== -1 && (
					<NavItem
						title="Juguetes"
						selected={category === 'Juguetes' ? true : false}
						handleClick={() => setCategory('Juguetes')}
					>
						<path d="M 16 1 C 14.894531 1 14 1.894531 14 3 C 14 3.738281 14.402344 4.371094 15 4.71875 L 15 7 L 10 7 C 7.25 7 5 9.25 5 12 L 5 14 L 2 14 L 2 22 L 5 22 L 5 27 L 27 27 L 27 22 L 30 22 L 30 14 L 27 14 L 27 12 C 27 9.25 24.75 7 22 7 L 17 7 L 17 4.71875 C 17.597656 4.371094 18 3.738281 18 3 C 18 1.894531 17.105469 1 16 1 Z M 10 9 L 22 9 C 23.667969 9 25 10.332031 25 12 L 25 25 L 23 25 L 23 20 L 9 20 L 9 25 L 7 25 L 7 12 C 7 10.332031 8.332031 9 10 9 Z M 12 13 C 10.894531 13 10 13.894531 10 15 C 10 16.105469 10.894531 17 12 17 C 13.105469 17 14 16.105469 14 15 C 14 13.894531 13.105469 13 12 13 Z M 20 13 C 18.894531 13 18 13.894531 18 15 C 18 16.105469 18.894531 17 20 17 C 21.105469 17 22 16.105469 22 15 C 22 13.894531 21.105469 13 20 13 Z M 4 16 L 5 16 L 5 20 L 4 20 Z M 27 16 L 28 16 L 28 20 L 27 20 Z M 11 22 L 13 22 L 13 25 L 11 25 Z M 15 22 L 17 22 L 17 25 L 15 25 Z M 19 22 L 21 22 L 21 25 L 19 25 Z" />
					</NavItem>
				)}
				{allCats.indexOf('Membresía') !== -1 && (
					<NavItem
						title="Membresía"
						selected={category === 'Membresía' ? true : false}
						handleClick={() => setCategory('Membresía')}
					>
						<path
							fill="currentColor"
							d="M4 9C4 8.44772 4.44772 8 5 8H9C9.55228 8 10 8.44772 10 9C10 9.55228 9.55228 10 9 10H5C4.44772 10 4 9.55228 4 9Z"
						/>
						<path
							fill="currentColor"
							fillRule="evenodd"
							d="M4 3C1.79086 3 0 4.79086 0 7V17C0 19.2091 1.79086 21 4 21H20C22.2091 21 24 19.2091 24 17V7C24 4.79086 22.2091 3 20 3H4ZM20 5H4C2.89543 5 2 5.89543 2 7V14H22V7C22 5.89543 21.1046 5 20 5ZM22 16H2V17C2 18.1046 2.89543 19 4 19H20C21.1046 19 22 18.1046 22 17V16Z"
							clipRule="evenodd"
						/>
					</NavItem>
				)}
				{allCats.indexOf('Muebles y Oficinas') !== -1 && (
					<NavItem
						title="Muebles y Oficinas"
						fill={false}
						stroke={2}
						selected={
							category === 'Muebles y Oficinas' ? true : false
						}
						handleClick={() => setCategory('Muebles y Oficinas')}
					>
						<path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48" />
					</NavItem>
				)}
				{allCats.indexOf('Vinos y Licores') !== -1 && (
					<NavItem
						title="Vinos y Licores"
						selected={category === 'Vinos y Licores' ? true : false}
						handleClick={() => setCategory('Vinos y Licores')}
					>
						<path d="M20.832,4.555c0.204-0.307,0.224-0.701,0.05-1.026S20.369,3,20,3H4C3.631,3,3.292,3.203,3.118,3.528 s-0.154,0.72,0.05,1.026L11,16.303V20H8v2h8v-2h-3v-3.697L20.832,4.555z M12,14.197L8.535,9h6.93L12,14.197z M18.132,5l-1.333,2 H7.201L5.868,5H18.132z" />
					</NavItem>
				)}
			</ul>
		</nav>
	);
}

export default NavBar;
